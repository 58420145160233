import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { Pagination } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import dayjs from 'dayjs';
import Button from "@mui/material/Button";
import { CustomModal } from "../../shared/common/utilites/customModal";
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../scheduler/scheduler.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material/";
import { useFormik } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { getInvoiceShedulers,deleteInvoiceSheduler,updateInvoiceSheduler } from "../../services/Services";
import { createObject, encryptForm } from "../../services/commonService";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { getEncryptedItem } from "../../services/localStorageUtil";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "20px",
  background: "unset",
  border: "2px solid #8B9635",
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === "light" ? "black" : "#fff",
  "&:hover": {
    backgroundColor: "#8B9635",
    color: "#000",
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "black",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));

const useStyles = makeStyles({
  customNumberField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
  },
});

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
  "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#8B9635",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "unset",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "unset",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#8B9635",
    color: "#000",
    "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#8B9635",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
}));

const defaultItems = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  search:''};

const marginTopValue = '104px';
function Schduler() {
  const { t, i18n } = useTranslation(["language", "common"]);
  const [schedulerData, setSchedulerData] = useState([]);
  const [schedulerCount, setSchedulerCount] = useState(0);
  const [schedulerParams, setSchedulerParams] = useState(defaultItems);
  const [toggleIcon, setToggleBtn] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [schedulerStartDate, setSchedulerStartDate] = useState(null);
  const classes = useStyles();
  const currentLanguage = i18n.language;
  const [openErrorModal,setOpenErrorModal] =useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptNoReminder,setAcceptNoReminder]=useState(false);


  const acceptReminder = () =>{
    formik.submitForm();
  }
  const CustomLoadingOverlay = ()=>{
    return <Loader />;
  }

  const handleOpenModel = ()=>{
    if (!selectedRowIds.length){return}
    setOpenDeleteModal(true);
  }

  const localeText = {
    noRowsLabel: t("common_no_rows"),
    footerRowSelected: (count) => `${count} ${t("common_row_selected")}`,
  };
  const updatehandleClose = () =>{
    setAcceptNoReminder(false);
    setOpenErrorModal(false);
  }
  function CustomNoRowsOverlay() {
    const { t } = useTranslation(["language", "common"]);
    return (
      <GridOverlay>
        <Typography>{t("common_no_data_available")}</Typography>
      </GridOverlay>
    );
  }

  const handleDelete =()=>
  {
    try {
      const d = new FormData();
      d.append("cid", selectedRowIds);
      d.append("userName",userDetails.username?userDetails.username:'');
      //d.append("isAuthUser", "");
      let object = createObject(d);
      let encryptedToken = encryptForm(object);
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      deleteInvoiceSheduler(encrData)
       .then((res) => {
          setOpenDeleteModal(false);
          if (res.status) {
            loadSchedulerTable();
            openAlertSnackBar('success',t(res.message),marginTopValue);
          }
          else {
            openAlertSnackBar('error',t(res.message),marginTopValue);
          }
          })
             .catch((err) => {
               setTableLoader(false);
               openAlertSnackBar('error','common_alert',marginTopValue);
             });
      } catch (error) {
        openAlertSnackBar('error','common_alert',marginTopValue);
      }
  }

  const handleNumber = (event)=>{
    const newValue = event.target.value;
    if (/^-?[0-9]*$/.test(newValue)) {
      formik.handleChange();
    }
  };

  const DeleteModalContent=()=>{
    return(
      <>
        <p id="child-modal-description">{t("confirm_delete")}</p>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <CustomBtn onClick={handleCloseDeleteModal} sx={{ mt: 2 }}>
            {t("common_cancel")}
          </CustomBtn>
          <CustomBtn onClick={handleDelete} sx={{ mt: 2 }}>
            {t("common_delete")}
          </CustomBtn>
        </Box>
      </>)
  }

  const handlePageChange = (event, newPage) => {
    setSchedulerParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const handlePageSizeChange = (event) => {
    setSchedulerParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const formatDate = useCallback(
    (data) => {
      if (!data){return null}
      if (currentLanguage === "en") {
        return new Date(data).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      } else {
        return new Date(data).toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      }
    },
    [currentLanguage]
  );
  const ErrorModalContent = () => {
    return (
      <>
      <div style={{marginTop:"2%",marginBottom:"2%"}}>{errorMessage}</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          
          <CustomBtn onClick={updatehandleClose} sx={{ mt: 2 }}>
             {t("common_cancel")}
          </CustomBtn>
          <CustomBtn onClick={acceptReminder} sx={{ mt: 2 }}>
          {t("common_submit")}
          </CustomBtn>
      
        </div>
      </>
    );
  };
  useEffect(() => {
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
    return()=>{
      closeAlertSnackBar();
    }
  }, []);

  const intervalTypes=[
    {
      id:1,
      label:'Day/s',
      code:'d'
    },
    {
      id:2,
      label:'Week/s',
      code:'w'
    },
    {
      id:3,
      label:'Month/s',
      code:'m'
    },
    {
      id:4,
      label:'Year/s',
      code:'y'
    }
  ];

  const columns = [
    { field: "invoiceid", headerName: t("invoiceid"), flex: 1 },
    { field: "name", headerName: t("common_name"), flex: 1 },
    { field: "companyname", headerName: t("common_company_name"), flex: 1.2 },
    { field: "startdate", headerName: t("common_duedate"), flex: 0.8,
      valueFormatter:(params) =>formatDate(params.value)},
    { field: "nextdate", headerName: t("common_nextdate"), flex: 0.8,sortable:false,
      valueFormatter:(params) =>formatDate(params.value)},
    { field: "enddate", headerName: t("end_date"), flex: 0.8,
      valueFormatter: (params) => formatDate(params.value),
      cellClassName: (params) => {
        const isClicked = params.id === selectedRowIds[0];
        if (params.row.isexpired === 'true' && !isClicked) {
          return 'red-background'; 
        }
        return '';
      }
    },
    { field: "interval", headerName: t("common_interval"), flex: 0.5 ,sortable:false},
    { field: "type", headerName: t("type"), flex: 0.6,sortable:false,
      renderCell: (params) =>  
      {
        const interval = intervalTypes.filter((item)=>item.code === params.value);
        return (interval[0].label);
      }
    }
  ];



  const loadSchedulerTable = useCallback(() => {
    const controllerLoadItem = new AbortController();
    const signalLoadItem = controllerLoadItem.signal;
    const d = new FormData();
    d.append("limit", schedulerParams.limit);
    d.append("page", schedulerParams.page);
    d.append("column", schedulerParams.column);
    d.append("order", schedulerParams.order);
    d.append("search", schedulerParams.search);
    //d.append("isAuthUser", '');
    setTableLoader(true);
    setSchedulerData([]);
    
    let objectItem = createObject(d);
    let encryptedToken = encryptForm(objectItem);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    getInvoiceShedulers(encrData, signalLoadItem)
      .then((res) => {
        if(isMounted)
        {
          setTableLoader(false);
          if (res.status) {
            if(res.data.length>0)
            {
              setSchedulerData(res.data);
              setSchedulerCount(Number(res.filtered));
            }else{
              setSchedulerCount(0);
            }
          } else {
            openAlertSnackBar('error',t(res.message),marginTopValue);
          }
        }
      })
      .catch((err) => {
        if(isMounted)
        {
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        }
      });

    return () => {
      controllerLoadItem.abort();
      setIsMounted(false);
    };
  }, [schedulerParams,isMounted]);

  useEffect(() => {
    setIsMounted(true);
    loadSchedulerTable();
    return ()=>{
      setIsMounted(false);
    }
  }, [loadSchedulerTable]);

  const initialValuesCreateInvoice = {
    name:'',
    invoiceid:'',
    startdate:null,
    enddate:null,
    interval:'',
    type:'',
    address:'',
    isexpired:''
  };
  
  const changeDateformat=(date)=>{
    const dateFormat =date.split('/');
    return `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`
  }

  const validationSchema = Yup.object().shape({
    name:Yup.string().required(t('common_validate_name')),
    enddate:Yup.date().nullable().test('is-after-startdate', t('common_validate_enddate_precedes_startdate'), function(value) {
      if(!value)return true
      const startDate = new Date(this.parent.startdate); 
      return startDate && value && value > startDate; 
    }),
    interval:Yup.number().typeError("common_validation_input_number").required(t('common_validate_interval')),
    type:Yup.string().required(t('common_validate_type')),
  });

  const formateD =(data) => {
    return new Date(data).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  const formik = useFormik({
    initialValues: initialValuesCreateInvoice,
    validationSchema,
    onSubmit: (values) => {
      if(toggleIcon){
        let schduleValues = {
          name:values.name,
          startdate:values.startdate,
          enddate:values.enddate,
          interval:values.interval,
          type:values.type
        };
        const startdate=values.startdate ? formateD(values.startdate):'';
        const enddate=values.enddate ? formateD(values.enddate):'';
        schduleValues.enddate=enddate ? changeDateformat(enddate):null;
        schduleValues.startdate = startdate ? changeDateformat(startdate):'';
        const updateSchedulerForm = new FormData();
        if (acceptNoReminder) {
          updateSchedulerForm.append('acceptNoReminder', 'true');
        }
        updateSchedulerForm.append("schedulerdata",JSON.stringify(schduleValues));
        updateSchedulerForm.append("userName",userDetails.username?userDetails.username:'');
        updateSchedulerForm.append("cid",selectedRowIds[0]);
        //updateSchedulerForm.append("isAuthUser",'');
        let object = createObject(updateSchedulerForm);
        let encrptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken",encrptedToken);
        updateInvoiceSheduler(encrData)
          .then(res=>{
            if(res.status)
            {
              loadSchedulerTable();
              setAcceptNoReminder(false);
              setOpenErrorModal(false);
              openAlertSnackBar('success',t(res.message),marginTopValue);
            }
            else{
              if(res.no_reminder)
               {
                 setAcceptNoReminder(true);
                 setErrorMessage(t(res.message));
                 setOpenErrorModal(true);
               }
              openAlertSnackBar('error',t(res.message),marginTopValue);
             }
          })
          .catch(()=>{
            openAlertSnackBar('error','common_alert',marginTopValue);
          })
      }
    },
  });

  const resetSchedulerForm = () => {
    setSelectedRowIds([]);
    setToggleBtn(false);
    formik.resetForm();
  };


  const handleSchedulerSelectionChange = (newSelection) => {
    if (newSelection.length === 0) {
      formik.resetForm();
      setSelectedRowIds([]);
      setSchedulerStartDate(null);
      setToggleBtn(false);
      return;
    }

    setSelectedRowIds(newSelection);
    if (selectedRowIds[0] === newSelection[0]) {
      formik.resetForm();
      setSelectedRowIds([]);
      setToggleBtn(false);
    } else {
      setToggleBtn(true);
      const filteredSchedulerObject = schedulerData.filter(
        (obj) => obj.cid === newSelection[newSelection.length - 1]
      );
      
      if(filteredSchedulerObject.length===0)
      {
        return;
      }
      setSchedulerStartDate(dayjs(filteredSchedulerObject[0]?.startdate))
      let schedulerObj =initialValuesCreateInvoice;
      schedulerObj={
        name:filteredSchedulerObject[0].name?filteredSchedulerObject[0]?.name:'',
        invoiceid: filteredSchedulerObject[0].invoiceid ? filteredSchedulerObject[0]?.invoiceid:'',
        address: filteredSchedulerObject[0].companyname ? filteredSchedulerObject[0]?.companyname:'',
        startdate: dayjs(filteredSchedulerObject[0]?.startdate),
        enddate: filteredSchedulerObject[0].enddate ? dayjs(filteredSchedulerObject[0]?.enddate):null,
        interval: filteredSchedulerObject[0]?.interval,
        type: filteredSchedulerObject[0]?.type,
      }
      formik.setValues(schedulerObj);
    }
  };

  const lastSelectedRowIndex =
    selectedRowIds.length > 0
      ? selectedRowIds[selectedRowIds.length - 1]
      : null;

  const getRowClassName = useCallback(
    (params) => {
      if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
        return "commonSelected";
      }
      if (lastSelectedRowIndex === null) {
        return "commonSelected";
      }
      return "";
    },
    [lastSelectedRowIndex]
  );
  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setSchedulerParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setSchedulerParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setSchedulerParams((previousParams)=>({
        ...previousParams,
        search:event.target.value,
        page:1
      }))
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="MuiDataGrid-virtualScroller">
        <Box sx={{ flexGrow: 1, margin: "10px 0 0 0" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} >
              <Grid item>
                <span className="commonCreateLabel">
                  {t("scheduler")}
                </span>
              </Grid>
              <Grid item>
                <Item >
                  <div className="actionSection">
                    {toggleIcon && (
                      <div className="commonActionItem">
                        <IconButton onClick={formik.handleSubmit}>
                          <EditIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                        </IconButton>
                        <span>{t("common_update")}</span>
                      </div>
                    )}
                    <div className="commonActionItem">
                      <IconButton onClick={resetSchedulerForm}>
                        <CloseSharpIcon
                          sx={{ fontSize: 30, color: "#ff0000" }}
                        />
                      </IconButton>
                      <span>{t("common_cancel")}</span>
                    </div>
                    <Box
                      className="commonActionItemEnd"
                      display="flex"
                      justifyContent="flex-end"
                    >
                    </Box>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <CustomBox
                  className="leftSection"
                  sx={{
                    minHeight: "725px",
                    height: "auto",
                    boxSizing: "borderBox",
                  }}
                >
                <Grid item container spacing={1}>
                  <Grid item container xs={12}>
                    <Grid item xs={8} sx={{display: "flex" }}>
                      <span style={{ color: "red",fontSize: '14px'}}>{'*'+t("scheduler_duedate_warning")}</span>
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={4}>
                      {toggleIcon ? (
                        <span style={{ color: "#8B9635" }}>
                          {t("template_edit_mode")}
                        </span>):(<span  style={{padding:'12px'}}/>)}
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item  xs={12} sm={6}>
                      <TextField
                        id="name"
                        label={t("common_name")}
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        {...formik.getFieldProps('name')}
                      />
                    </Grid>
                    <Grid item  xs={12} sm={6}>
                      <TextField
                        id="invoiceid"
                        label={t("invoiceid")}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                        value={formik.values.invoiceid}
                        onChange={formik.handleChange}
                        {...formik.getFieldProps('invoiceid')}
                        />
                    </Grid>
                  </Grid>
                  <Grid item  xs={12}>
                    <TextField
                        id="address"
                        label={t("common_company_name")}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        {...formik.getFieldProps('address')}
                        />
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item  xs={12} sm={6}>
                      <TextField
                        id="interval"
                        label={t("common_interval")+'*'}
                        variant="outlined"
                        fullWidth
                        className={classes.customNumberField}
                        size="small"
                        type="number"
                        value={formik.values.interval}
                        onChange={handleNumber}
                        {...formik.getFieldProps('interval')}
                        error={formik.touched.interval && Boolean(formik.errors.interval)}
                        helperText={formik.touched.interval && formik.errors.interval}
                        />
                    </Grid>
                    <Grid item  xs={12} sm={6}>
                      <TextField
                          id="type"
                          label={t("type")+'*'}
                          variant="outlined"
                          fullWidth
                          select
                          size="small"
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          {...formik.getFieldProps('type')}
                          error={formik.touched.type && Boolean(formik.errors.type)}
                          helperText={formik.touched.type && formik.errors.type}
                          >
                            {intervalTypes?.map((option) => (
                            <MenuItem key={option.id} value={option.code} >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item  xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                        <DatePicker
                          id="startdate"
                          name="startdate"
                          label={t('common_duedate')+'*'}
                          format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                          variant="small"
                          inputVariant="outlined"
                          size="small"
                          // minDate={(!toggleIcon) ? dayjs() : schedulerStartDate}
                          minDate={ dayjs() }
                          value={formik.values.startdate}
                          slotProps={{
                            textField: { 
                                size: 'small' ,
                                variant: "outlined",
                                error: (!toggleIcon) && formik.touched.startdate && Boolean(formik.errors.startdate),
                                helperText:  formik.touched.startdate && formik.errors.startdate
                                }
                              }}
                          onChange=
                          {
                            (newValue) => {
                              formik.setFieldValue('startdate', newValue);
                              formik.touched.enddate =true;
                            }
                          }
                          onBlur={formik.handleBlur}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item  xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                        <DatePicker
                            id="enddate"
                            name="enddate"
                            label={t('end_date')}
                            format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                            variant="small"
                            inputVariant="outlined"
                            size="small"
                            // minDate={formik.values.startdate?formik.values.startdate.add(1, 'day'):dayjs().add(1, 'day')}
                            minDate={dayjs().add(1, 'day')}
                            value={formik.values.enddate}
                            slotProps={{
                            textField: {
                                size: 'small' ,
                                variant: "outlined",
                                error: formik.touched.enddate && Boolean(formik.errors.enddate),
                                helperText: formik.touched.enddate && formik.errors.enddate
                                }
                              }}
                            onChange={(value) => formik.setFieldValue("enddate", value, true)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField {...params}
                                error={formik.touched.enddate && Boolean(formik.errors.enddate)}
                                helperText={formik.touched.enddate && formik.errors.enddate}
                              />
                            )}
                          />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  </Grid>
                </CustomBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="commonCreateLabel">
                {t("common_list_scheduler")}
              </span>
              <Item>
              <div className="actionSectionRight">
                <div className="commonactionRight">
                    <div className="commonActionItem">
                      <IconButton onClick={handleOpenModel}>
                        <DeleteIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                      </IconButton>
                      <span>{t("common_delete")}</span>
                    </div>
                  </div>
                  <div className="searchInvoiceDiv">
                    <TextField
                      label={t("common_search")}
                      id="searchInvoices"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onKeyDown={handleSearchKeyDown}
                      sx={{ mb: 1, width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Item>
              <div style={{  height: "725px", marginTop: "10px" }}>
                <CustomDataGrid
                  rows={schedulerData}
                  getRowId={(row) => row.cid}
                  columns={columns}
                  rowHeight={33}
                  headerHeight={40}
                  pagination
                  rowsPerPageOptions={[10, 20]}
                  onPageChange={handlePageChange}
                  page={schedulerParams.page-1}
                  getRowClassName={getRowClassName}
                  selectionModel={selectedRowIds}
                  onSortModelChange={handleSortModelChange}
                  onSelectionModelChange={handleSchedulerSelectionChange}
                  pageSize={schedulerParams.limit}
                  loading={tableLoader}
                  onPageSizeChange={handlePageSizeChange}
                  paginationMode="server"
                  paginationComponent={Pagination}
                  rowCount={schedulerCount}
                  localeText={localeText}
                  scrollbarSize={0}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                  disableColumnMenu
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: t("common_rows_per_page"),
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

      <CustomModal
        openModal={openDeleteModal}
        onclose={handleCloseDeleteModal}
        header={t("common_delete")}
        content={DeleteModalContent}
      />
       <CustomModal
          openModal={openErrorModal}
          onclose={updatehandleClose}
          header={'Invoice'}
          content={ErrorModalContent}
        
        />
    </>
  );
}

export default Schduler;
