import { createContext, useEffect, useState } from "react";
import { getEncryptedItem, setEncryptedItem } from "../services/localStorageUtil";

const initialState = {
    onChangeMode: (mode) => {}
  };
  
const ConfigContext = createContext(initialState);

function ConfigProvider({ children }) {
    const [mode, setModes] = useState('dark');

    useEffect(()=>{
        // let themeMod = localStorage.getItem("theme");
        let themeMod = getEncryptedItem('userData');
        if(themeMod){
            setModes(themeMod);
        }
    },[]);
    
    const onChangeMode = (modes) => {
        if (modes === 'light'){
            // localStorage.setItem("theme", 'dark') ;
            setEncryptedItem('theme', 'dark');
            setModes('dark');
        }else{
            // localStorage.setItem("theme", 'light');
            setEncryptedItem('theme', 'light');
            setModes('light');
        }
    };

    return (
      <ConfigContext.Provider
        value={{
          onChangeMode,
          mode
        }}
      >
        {children}
      </ConfigContext.Provider>
    );
  }

export {ConfigContext,ConfigProvider};