import { useTheme } from "@emotion/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {  ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NavItem =({menu,location,isChild})=>{
      const theme = useTheme();
      // console.log("NAViTEM",menu);
      const {  t } = useTranslation(["language", "common"]);
    return(
        <>
        <ListItem  id={menu.path} disablePadding
         component={Link}
         to={menu.path}
        //   onClick={(e) => menu.nested ? handleNestedMenuClick(menu.id,menu.nested): routeTo(e, index) }
          sx={(location.pathname === '/' + menu.path) ? { backgroundColor: '#8B9635', color: '#000' } :{color: theme.palette.mode === "dark" ?'#fff':'#000'}}
          className={ location.pathname === '/' + menu.path ? 'selectedList' : ''}
        >
            <ListItemButton title={t(menu.label)}>
                <ListItemIcon sx={{ color: (location.pathname === "/" + menu.path ) && '#000', minWidth:'30px',paddingLeft:(isChild)&& '10px' }} >
                  {menu.icon}
                </ListItemIcon>
                <ListItemText primary={t(menu.label)} />
                {/* {menu.nested && (<div style={{display:'flex',margin:'0px -14px 0px 0px'}}>{nestedSelection ? (<ExpandLess />) : (<ExpandMore />)}</div>)} */}
            </ListItemButton>
        </ListItem>
        </>
    )
}

export default NavItem;